import * as c from "../utils/constants/constants";
/*==========logs out the user based on type of login. if login type is SSO, its re-directed to ssoRedirect page, else redirectd to root page*/
export const forceLogout = () => {
  console.log('force logout');
  localStorage.clear();
      if(c.SSO === 'true')
        window.location.pathname = `/${c.PAGE_URLS[c.SSO_REDIRECT]}`;
      else
        window.location.pathname = `/${c.PAGE_URLS[c.PAGE_LOGIN]}`;
      return;
}