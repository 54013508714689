import axios from 'axios';
import { forceLogout } from 'commonFunctions/forceLogout';
import * as c from '../constants/constants';
import * as msg from "../constants/messages";

const client = axios.create({
    baseURL: "https://dev01-api.readabl.ai",
});

client.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => Promise.reject(error)
);

client.interceptors.response.use(
    (response) => {
        localStorage.setItem("r_available", true)
        return response
    },
    (error) => {
        if (error.response) {
            console.error('Response Error:', error.response.data?.message || 'Unknown error occurred');
            if (error.response.status === 503) {
                localStorage.setItem("r_available", false)
                window.location.href = `/${c.PAGE_READABL_DOWN}`;
            } else if (error.response.status === 401) {
                localStorage.clear();
                forceLogout();
            } else if (error.response.status === 429)
                window.alert(msg.rate_limiter);
        } else {
            console.error('Network Error:', error.message || 'Network error occurred');
        }
        return error;
    }
);

export default client;