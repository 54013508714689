import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as c from "../../utils/constants/constants";
import "./SomethingWentWrong.css";
import ReadablLogo from "../NavigationMenu/ReadablLogo";
import api from "utils/API/api";
import { CircularProgress } from "@mui/material";

const SomethingWentWrong = (props) => {
    const navigate = useNavigate();
    const [loading, updateLoading] = useState(null);

    const goToHomePage = () => {
        if(localStorage.getItem(c.ACCESS_TOKEN) &&  localStorage.getItem(c.USER_ROLE) == "Staff") navigate(`/${c.PAGE_URLS[c.PAGE_DATA]}/${c.PAGE_URLS[c.PAGE_DOC_MGMT]}`);
        else {
            if(c.SSO == "true") navigate(`/${c.PAGE_URLS[c.SSO_REDIRECT]}`);
            else navigate(`/`);
        }
    }
    
    const checkApplicationAvailability = () => {
        updateLoading(true);
        api.getUserProfile()
        .then(res => {
            if(res.status == 200) goToHomePage()
        })
        .finally(() => {
            updateLoading(false)
        })
    }

    return <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginTop: "15px", alignItems: "center", height: "300px" }}>
        {loading ? <CircularProgress size={30} className="common-spinner"/> :
        <>
            <h1 className="not-found-text">Temporarily Down for Maintenance</h1>
            <div className="info">We are perfoming scheduled maintenance. We should be back online shortly. <span className="info-link" style={{fontSize: "inherit"}} onClick={checkApplicationAvailability}>Click here</span> to refresh.</div>
        </>}
        
    </div>
}

export default SomethingWentWrong;