import client from '../../../utils/API/interceptor';
import { userProfileActions } from './actionCreators';
//import { getLocations_user_doc } from '../../docmanagement/actions/actions';
import {getToken} from "../../../commonFunctions/getToken";

export const getUserProfileData = ((url) => {
    return async(dispatch) => {
        dispatch(userProfileActions.getUserProfileLoading(true));
        let token = await getToken();
        let options = {headers: {
            Authorization: "Bearer " + token,
          }};
        client.get(url,options)
            .then((response) => {
                if(response.status !== 200) {
                    throw Error();
                }
                dispatch(userProfileActions.getUserProfileLoading(false));
                return response;
            })
            .then((response) => {
                dispatch(userProfileActions.getUserProfileSuccess(response.data));
                //dispatch(getLocations_user_doc(c.ROOT_URL+"/getLocationsForUser/" + response.data.data[0].user_id));
            })
            .catch((error) => dispatch(userProfileActions.getUserProfileError(error.response.status)));
    }
});